import React from 'react';
import ReactDOM from 'react-dom';

import NotFoundC from './NotFound';

const element = document.getElementById('app');

const context = window.__INITIAL__DATA__ ? window.__INITIAL__DATA__ : {
  context: {
    recipes: [],
    actions: [],
    app: appData
  }
}
const app = (
  <NotFoundC data={{ ...context }} />
  // <div>Not found</div>
);
// In production, we want to hydrate instead of render
// because of the server-rendering
if (process.env.NODE_ENV === 'production') {

  console.log("window.__INITIAL__DATA__", window.__INITIAL__DATA__)
  console.log("hydrating", context)
  ReactDOM.hydrate(app, element);

} else {
  console.log("rendering", context)
  ReactDOM.render(app, element);
}

// Hot reload is that easy with Parcel
if (module.hot) {
  module.hot.accept();
}
