import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
// import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { HeadWithDataUri } from "../../components/common/TitleAndHeader";


const pagenotfound = require("../../assets/images/icons-web/404_img.svg");

export default function NotFound({ orginalUrl }) {

  // console.log("Terams and conditions render", orginalUrl)
  React.useEffect(() => {
    // console.log("404 use effect")
    // document.getElementsByTagName("html")[0].style.visibility = "visible";
    // document.getElementById("loader-wrapper").style.display = "none";
  }, [])

  return (
    <>
      <HeadWithDataUri
        orginalUrl={orginalUrl}
        title="Workativ – Page Not Found"
        description="Workativ – Page Not Found"
        keywords={["workativ terms and conditions"]}
        ogTitle="Workativ – Page Not Found"
        ogDescription="Workativ – Page Not Found"
      />
      <Container>
        <Layout
          logoFor="ASSISTANT"
          backgroundColor={"bg_feature _assistant_pricing"}
        >
          <section
            className="page-not-found-container float-left"
            style={{ width: "100%", padding: "100px 0px;" }}
          >
            <div class="container">
              <img src={pagenotfound} className="page-not-found-image"></img>
            </div>
          </section>
        </Layout>
      </Container>
    </>
  );
}

// function Page404() {
//   return (
//     <>
//       <section className="timeline-year-workplace">
//         <div className="container">
//           <div className="row">
//             <div className="col-sm-12">
//               <div className="pos-rel">
//                 {/*404 Error Start*/}
//                 <section className="error-page-workplace">
//                   <div className="container">
//                     <div className="row">
//                       {/*404 Content*/}
//                       <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
//                         <div className="error-content">
//                           <img
//                             src={require("../../assets/images/vid-product-demo.png")}
//                             alt="About Us Growth"
//                           />
//                           <h3>Workativ Assistant Product Intro</h3>
//                           <p>
//                             Workativ Assistant is a cognitive workplace
//                             automation suite designed to transform the way
//                             companies provide workplace IT support to employees.
//                           </p>
//                         </div>
//                       </div>
//                       {/*404 Content*/}
//                       <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
//                         <div className="error-content">
//                           <img
//                             src={require("../../assets/images/vid-create-ticket-slack.png")}
//                             alt="About Us Growth"
//                           />
//                           <h3>Create ticket using Workativ Assistant VA</h3>
//                           <p>
//                             Now create a support ticket using our Workativ
//                             Assistant VA from Slack.
//                           </p>
//                         </div>
//                       </div>
//                       {/*404 Content*/}
//                       <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
//                         <div className="error-content">
//                           <img
//                             src={require("../../assets/images/vid-unlock-account.png")}
//                             alt="About Us Growth"
//                           />
//                           <h3>Unlock Account using Workativ Assistant VA</h3>
//                           <p>
//                             Unlock your account using Workativ Assistant VA from
//                             slack anytime anywhere.
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </section>
//                 {/*404 Error End*/}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       ;
//     </>
//   );
// }
